/* Navbar container */
nav {
  position: relative;
  z-index: 50; /* Ensure navbar stays above other elements */
}

/* Phone navbar - hidden by default, positioned below the navbar */
.phone-nav {
  position: absolute;
  top: 100%; /* Position directly below navbar */
  left: 0;
  width: 100%;
  background-color: var(--nav-background); /* Replace with your design colors */
  transform: translateY(-100%);
  opacity: 0;
  pointer-events: none;
  transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
  z-index: 40; /* Below navbar */
}

/* Open state for the phone nav */
.phone-nav.open {
  transform: translateY(0);
  opacity: 1;
  pointer-events: auto;
}

/* Hide phone-nav on larger screens */
@media (min-width: 1024px) {
  .phone-nav {
    display: none;
  }
}


@keyframes fadeUpAnimation {
  0% {
    opacity: 0;
    transform: translateY(20px); /* Start slightly below */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* End at the original position */
  }
}

.fade-up-animation {
  opacity: 0; /* Start invisible */
  animation: fadeUpAnimation 1s ease-in-out forwards; /* Customize duration and easing as needed */
}

.fade-up-animation:nth-child(2) {
  animation-delay: 300ms;
}
.fade-up-animation:nth-child(3) {
  animation-delay: 600ms;
}
.fade-up-animation:nth-child(4) {
  animation-delay: 900ms;
}


/* CHAT BOT STYLES AND ANIMATIONS */

.slider {
  width: 100px;
  height: 125px;
  top: 22%;
  transform-style: preserve-3d;
  transform: perspective(1000px);
  animation: autoRun 150s linear infinite;
  z-index: 2;
}

@keyframes autoRun {
  0% {
    transform: perspective(1000px) rotateX(-12deg) rotateY(0deg);
  }
  50% {
    transform: perspective(1000px) rotateX(-26deg) rotateY(180deg);
  }
  100% {
    transform: perspective(1000px) rotateX(-12deg) rotateY(360deg);
  }
}

.slider .item {
  position: absolute;
  inset: 0 0 0 0;
  transform: rotateY(
      calc((var(--position) - 1) * (360 / var(--quantity)) * 1deg)
    )
    translateZ(350px);
}

.slider .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 1023px) {
  .slider {
    width: 160px;
    height: 200px;
    left: calc(50% - 80px);
  }
  .slider .item {
    transform: rotateY(
        calc((var(--position) - 1) * (360 / var(--quantity)) * 1deg)
      )
      translateZ(300px);
  }
}
@media screen and (max-width: 767px) {
  .slider {
    width: 100px;
    height: 150px;
    left: calc(50% - 50px);
  }
  .slider .item {
    transform: rotateY(
        calc((var(--position) - 1) * (360 / var(--quantity)) * 1deg)
      )
      translateZ(180px);
  }
  .content h1 {
    font-size: 5em;
  }
}

.circle_animation {
  width: 750px;
  height: 750px;
  position: relative;
}

.circle_item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: center;
  opacity: 0;
  color: var(--colour1);

  /* Start animation */
  animation: moveToPosition 0.5s ease-in-out forwards;
  animation-delay: calc(var(--position) * 0.3s);

  transform: translate(
    calc(
      200px *
        cos(
          (calc((var(--position) - 1) * (360 / var(--quantity)) + 270) * 1deg)
        )
    ),
    calc(
      200px *
        sin(
          (calc((var(--position) - 1) * (360 / var(--quantity)) + 270) * 1deg)
        )
    )
  );
  translate: -50% -50%;
}

/* Keyframe animation */
@keyframes moveToPosition {
  0% {
    opacity: 0;
    /* Start at the 9 o'clock position */
    transform: translate(-200px, 0) scale(0.5); /* Start smaller at 9 o'clock */
  }
  35% {
    opacity: 0;
  }
  50% {
    transform: translate(
        calc(
          100px *
            cos(
              (
                calc((var(--position) - 1) * (360 / var(--quantity)) + 270) *
                  1deg
              )
            )
        ),
        calc(
          100px *
            sin(
              (
                calc((var(--position) - 1) * (360 / var(--quantity)) + 270) *
                  1deg
              )
            )
        )
      )
      scale(0.75); /* Halfway to the position */
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    /* Final position with full size */
    transform: translate(
        calc(
          350px *
            cos(
              (
                calc((var(--position) - 1) * (360 / var(--quantity)) + 270) *
                  1deg
              )
            )
        ),
        calc(
          250px *
            sin(
              (
                calc((var(--position) - 1) * (360 / var(--quantity)) + 270) *
                  1deg
              )
            )
        )
      )
      scale(1);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(200px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-up {
  animation: fadeInUp 1s forwards ease-in;
}

.service-item {
  opacity: 0;
} 

#robot-first-container {
}

.robot_label {
  opacity: 0;
  transform: translateY(-30px);
  transition: opacity 2s ease, transform 2s ease;
}

.animate_robot_label {
  opacity: 1;
  transform: translateY(0);
}

.no-transition {
  transition: none; /* Removes transition instantly */
}


/* Orange spinner */
@keyframes spin {
  0% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.spin {
  transform: rotate(270deg); /* Ensure initial state starts at 270deg */
  animation: spin 0.6s ease-in-out forwards;
}


@keyframes fadeIn {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.upand-in {
  animation: fadeIn 0.8s forwards ease-in;
}

@keyframes makeVisible {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.laptop_fade_in {
  opacity: 0;
  animation: makeVisible 2.2s forwards ease-in;
  animation-delay: 1.2s;
}

@keyframes appearingTitle {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.title_appearing {
  opacity: 0;
  animation: appearingTitle 2.2s forwards ease-in;
  animation-delay: 1.2s;
}

.fade-out {
  animation: fadeOut 2s forwards;
}

@keyframes fadeOut {
  to {
    opacity: 0;
    scale: 0.3;
    display: none;
    visibility: hidden;
  }
}