@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

:root {
  --colour1: #F7F7F7;
  --honolulu-blue: #0575AD;
  --colour3: #023047;
  --veronica: #9333EA;
  --colour5: #FB8500;
  --colour6: #f3bd80;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  border: 0;
  outline: 0;
  text-decoration: none;
}

@font-face {
  font-family: 'SevenSegment';
  src: url('../assets/fonts/SevenSegment.ttf') format('truetype');
}
@font-face {
  font-family: 'AbrilFatface';
  src: url('../assets/fonts/AbrilFatface-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Crackman';
  src: url('../assets/fonts/Crackman.otf') format('opentype');
}

@font-face {
  font-family: 'GaramondRegular';
  src: url('../assets/fonts/Garamond Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'MissEllen';
  src: url('../assets/fonts/Miss Ellen.ttf') format('truetype');
}

@font-face {
  font-family: 'PrinceValiant';
  src: url('../assets/fonts/PrinceValiant.ttf') format('truetype');
}

@font-face {
  font-family: 'TheQueen';
  src: url('../assets/fonts/TheQueen-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'TimesNewRoman';
  src: url('../assets/fonts/Times-New-Roman.ttf') format('truetype');
}


body {
  background-color: var(--colour1); /* Use the snow color */
  line-height: 1.5;
  min-height: 100vh;
  text-rendering: optimizeSpeed;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

html:focus-within {
  scroll-behavior: smooth;
}

ol,
ul {
  list-style: none;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
  padding: 0;
  margin: 0;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Media Queries (MEDIUM DEVICES) */

@media screen and (max-width: 1024px) {
}

/* Media Queries (SMALL DEVICES) */

@media screen and (max-width: 600px) {
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}


/* Overall scrollbar */
::-webkit-scrollbar {
  width: 12px;  /* Width of the scrollbar */
  height: 12px; /* Height of the scrollbar (for horizontal scrollbars) */
}

/* Scrollbar track (background) */
::-webkit-scrollbar-track {
  background: var(--colour1);  /* Light grey background for the scrollbar track */
}

/* Scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background-color: var(--colour3); /* Darker color for the thumb */
  border-radius: 10px;    /* Round the corners of the thumb */
  border: 3px solid var(--colour1); /* Optional: adds padding around the thumb */
}

/* Scrollbar thumb hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--colour3);  /* Darker color when hovered */
}

/* Scrollbar buttons (arrows) */
::-webkit-scrollbar-button {
  background-color: var(--colour1); /* Color of the arrow buttons */
  width: 12px;
  height: 12px;
  border-radius: 2px; /* Optionally round the corners */
}

/* Scrollbar button on hover */
::-webkit-scrollbar-button:hover {
  background-color: var(--colour3); /* Darker color when arrow buttons are hovered */
}
