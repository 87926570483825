.mockup {
  display: inline-block;
  position: relative;
  z-index: 3;
  text-align: center;
  font-size: 0;
  perspective: 2400px;
  perspective-origin: 50% 100%;
  opacity: 0;
  transition: 500ms opacity;
}

.mockup.loaded {
  opacity: 1;
}

.mockup .part .top,
.mockup .part .bottom {
  position: absolute;
  top: 0;
  left: 0;
}

.mockup .part.top {
  transform: translate3d(0, 0, 0) rotateX(-90deg);
}

.mockup.isActive .part.top {
  transform: translate3d(0, 0, 0) rotateX(0deg);
}

.mockup .part {
  display: inline-block;
  position: relative;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transform-origin: 50% 100%;
  transition: 1200ms;
}

.mockup.opened .part .top {
  transform: translate3d(0, 0, -11px) rotateX(90deg) scale(1, 1);
}

.mockup .part .top {
  transform-origin: 50% 0;
  transform: translate3d(0, 0, -11px) rotateX(90deg);
  transition: 900ms;
}

.x-class {
  opacity: 1; /* Element is visible */
  visibility: visible; /* Ensures the element is interactive while visible */
  transition: opacity 470ms ease-in, visibility 0s linear 470ms; /* Visibility delayed by 470ms */
}

.mockup.isActive .x-class {
  opacity: 0; /* Fade out */
  visibility: hidden; /* Hidden after fade-out completes */
  transition: opacity 470ms ease-in, visibility 0s linear 470ms; /* Sync visibility with opacity animation */
}

.mockup img .part {
  display: block;
  max-width: 100%;
  backface-visibility: hidden;
}

.mockup .part .cover {
  position: relative;
}

.mockup .part.bottom {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0) rotateX(-90deg);
}

.mockup .part .bottom {
  transform-origin: 50% 0;
  transform: translate3d(0, 0, 0) rotateX(90deg);
}

@keyframes borderShrink {
  from {
    background-color: var(--colour5);
  }
  to {
    background-color: var(--colour1);
  }
}

.laptop_border_animation {
  animation: borderShrink 2s forwards ease;
}

.scale-in-center {
  -webkit-animation: scale-in-center 1s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: scale-in-center 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.mockup .part.top img.main_portfolio_image {
  width: 100%;
  height: 100%;
  object-fit: fill; /* Ensures the image fills the container while maintaining aspect ratio */
  position: absolute; /* Keeps the image inside the container's bounds */
  top: 0;
  left: 0;
}
