.no__highlights {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.text-stroke-colour3 {
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: var(--colour3);
}

.text-stroke-black {
  text-shadow: -2px -2px 2px rgba(255,255,255,.2), 2px 2px 2px rgba(0,0,0,.5);
}

.hidden-container {
  display: none;
  visibility: hidden;
}

/* Invisible scroll */
.scrollbar-hidden {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
}

.scrollbar-hidden::-webkit-scrollbar {
  width: 0px;
}

.scrollbar-hidden::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.shine {
  border-radius: 0.4rem;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid var(--colour5);
}

.unlimited_grid_container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(135px, 1fr)); /* Dynamic column layout */
  gap: 1rem; /* Space between grid items */
  justify-content: center; /* Centers items in the last row automatically */
}

@media (max-width: 950px) {
  .unlimited_grid_container {
    grid-template-columns: repeat(4, minmax(135px, 1fr)); /* 4 columns for medium screens */
  }
}

@media (max-width: 650px) {
  .unlimited_grid_container {
    grid-template-columns: repeat(2, minmax(135px, 1fr)); /* 2 columns for small screens */
  }
}

/* Items default behavior */
.grid_item {
  grid-column: auto; /* Automatically span based on grid flow */
}

.five_item_grid {
  display: grid;
  grid-template-columns: repeat(6, minmax(135px, 1fr));
  gap: 1rem;
}

@media (max-width: 950px) {
  .five_item_grid {
    grid-template-columns: repeat(
      4,
      minmax(135px, 1fr)
    ); /* 4 columns on smaller screens */
  }

  .five_item:nth-child(4) {
    grid-column: 3 / span 2 !important; /* Adjust 4th item to span 1 column */
  }

  .five_item:nth-child(5) {
    grid-column: 2 / span 2; /* Adjust 5th item to span 2 columns */
  }
}

@media (max-width: 650px) {
  .five_item_grid {
    display: grid;
    gap: 48px;
    grid-auto-flow: row !important;
    grid-template-rows: 1fr !important;
    grid-template-columns: none;
  }
  .five_item {
    grid-column: span 1 !important; /* Default for all items */
  }
  .five_item:nth-child(4) {
    grid-column: span 1 !important;
  }
}

.five_item {
  grid-column: span 2; /* Default for all items */
}

/* Specific rule for 4th item on larger screens */
.five_item:nth-child(4) {
  grid-column: 2 / span 2;
}

@media (max-width: 1024px) {
  .three_item_grid {
    display: grid;
    grid-template-columns: repeat(4, minmax(135px, 1fr));
    gap: 2rem;
  }

  .three_item {
    grid-column: span 2 !important; /* Default for all items */
  }
  .three_item:nth-child(3) {
    grid-column: 2 / span 2 !important;
  }
}

@media (max-width: 700px) {
  .three_item_grid {
    display: grid;
    grid-template-columns: none !important;
    column-gap: 8rem;
    row-gap: 2rem;
    grid-template-rows: 1fr 1fr 1fr !important;
  }

  .three_item {
    grid-column: span 1 !important; /* Default for all items */
  }

  .three_item:nth-child(3) {
    grid-column: 1 / span 1 !important;
  }
}

.three_item_grid2 {
  display: grid;
  grid-template-columns: repeat(3, minmax(135px, 1fr));
}

@media (max-width: 1023px) {
  .three_item_grid2 {
    display: grid;
    grid-template-columns: repeat(4, minmax(135px, 1fr));
    row-gap: 4rem;
  }

  .three_item2 {
    grid-column: span 2 !important; /* Default for all items */
  }
  .three_item2:nth-child(3) {
    grid-column: 2 / span 2 !important;
  }
}

@media (max-width: 639px) {
  .three_item_grid2 {
    display: grid;
    grid-template-columns: none !important;
    column-gap: 8rem;
    row-gap: 4rem;
    grid-template-rows: 1fr 1fr 1fr !important;
  }

  .three_item2 {
    grid-column: span 1 !important; /* Default for all items */
  }

  .three_item2:nth-child(3) {
    grid-column: 1 / span 1 !important;
  }
}


.services_header_bg {
  /* Add the background image */
  background-image: linear-gradient(
      to top,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0)
    ),
    url('https://www.transparenttextures.com/patterns/gravel.png');

  /* Make the background image only cover the bottom 50% */
  background-size: 100% 100%;

  /* Position the image at the bottom */
  background-position: bottom;

  /* Make sure the background doesn't repeat */
  background-repeat: no-repeat;

  /* This blends the gradient and the image */
  background-blend-mode: soft-light;
}

.shadow_box {
  -webkit-box-shadow: 10px 10px 0px 1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 0px 1px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 0px 1px rgba(0, 0, 0, 0.75);
}

.neo_one {
  border-radius: 20px;
  background: #f7f7f7;
  box-shadow: 20px 20px 60px #d2d2d2, -20px -20px 60px #ffffff;
}

.neo_video {
  border-radius: 20px;
  box-shadow: 12px 12px 30px var(--colour5), -12px -12px 30px var(--colour5);
}

.neo_one:hover {
  border-radius: 20px;
  background: #f7f7f7;
  box-shadow: 20px 20px 60px #979797, -20px -20px 60px #ffffff;
}

.neo_review:hover {
  background: #f7f7f7;
  box-shadow: 8px 8px 12px #979797, -8px -8px 12px #ffffff;
}

.neo_round {
  border-radius: 50%;
  background: #f7f7f7;
  box-shadow: 20px 20px 22px #d2d2d2, -20px -20px 22px #ffffff;
}

.neo_orange {
  background: var(--colour5);
  box-shadow: 4px 4px 12px #d2d2d2, -4px -4px 12px #ffffff;
}

.neo_two {
  background: #f7f7f7;
  box-shadow: 6px 6px 20px #c1c1c1, -6px -6px 20px #ffffff;
}

.neo_sample_bg {
  background: #e9820c;
  box-shadow: 20px 20px 60px #c66f0a, -20px -20px 60px #ff960e;
}

.grid_spesh {
  grid-template-columns: repeat(
    auto-fit,
    minmax(150px, 1fr)
  ); /* Adapts the width of each item */
  grid-auto-rows: 1fr; /* Ensures rows adapt in height */
}

.input_shadow {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25) inset;
}

.button_shadow {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13), 0 1px 1px 0 rgba(0, 0, 0, 0.11);
}

.glass_bg {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.aspec_1 {
  aspect-ratio: 1 / 2; /* This ensures width is half of height */
}
